import { combineReducers } from 'redux';
import { actionTypes } from '../actions/production';


const params = (state = {}, action) => {
    switch (action.type) {
        case actionTypes.clearLiquidationData:
            return {}
        case actionTypes.setParams:
            return action.params
        default:
            return state
    }
}

const tasks = (state = [], action) => {
    switch (action.type) {
        case actionTypes.clearLiquidationData:
            return []
        case actionTypes.startLiquidationProcess:
            return []
        case actionTypes.addLiquidationTask:
            return [...state, action.task]
        default:
            return state
    }
}

const status = (state = 'idle', action) => {
    switch (action.type) {
        case actionTypes.clearLiquidationData:
            return 'idle'
        case actionTypes.startLiquidationProcess:
            return 'working'
        case actionTypes.finishLiquidationProcess:
            return 'finished'
        case actionTypes.pauseLiquidationProcess:
            return 'paused'
        case actionTypes.continueLiquidationProcess:
            return 'working'
        default:
            return state
    }
}

const customers = (state = [], action) => {
    switch (action.type) {
        case actionTypes.clearLiquidationData:
            return []
        case actionTypes.startLiquidationProcess:
            return []
        case actionTypes.setCustomersToLiquidate:
            return action.customers
        case actionTypes.setCustomerLiquidated:
            return [...state.map(c => c.customer.id === action.id ? {...c, status:'liquidated'} : c)]
        default:
            return state
    }
}


export default combineReducers({params, status, tasks, customers});

