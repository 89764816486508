import { combineReducers } from 'redux';
import { actionTypes } from '../actions/liquidation';
import { format, subMonths } from 'date-fns';

const params_default = {
        month:format(subMonths(new Date(),1), 'M'),
        year : format(subMonths(new Date(),1), 'yyyy'),
        customer: 'all',
        status:'all',
        noPrice:false
    };


const params = (state = params_default, action) => {
    switch (action.type) {
        case actionTypes.setParams:
            return action.params
        default:
            return state
    }
}

const loading = (state = false, action) => {
    switch (action.type) {
        case actionTypes.fetchLiquidations:
            return true
        case actionTypes.receiveLiquidations:
            return false
        default:
            return state
    }
}

const list = (state = [], action) => {
    switch (action.type) {
        case actionTypes.fetchLiquidations:
            return []
        case actionTypes.receiveLiquidations:
            return action.liquidations
        default:
            return state
    }
}

const selected = (state = [], action) => {
    switch (action.type) {
        case actionTypes.fetchLiquidations:
            return []
        case actionTypes.receiveLiquidations:
            return []
        default:
            return state
    }
}


export default combineReducers({ loading, list, selected,params});

