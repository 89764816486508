import React, { useState } from "react";
import { Autocomplete, Button, createFilterOptions, Dialog, DialogActions, DialogContent, DialogTitle, MenuItem, TextField, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import {  selectStudies } from "../../state/selectors/catalogs";

const PriceDialog = ({open, onClose, onSave, price}) => {
    
    const [data,setData] = useState(price);
    const studyTypes = useSelector(selectStudies);
    
    const handleChange = (event) => {
        setData({...data,[event.target.id || event.target.name]:event.target.value});
    }

    const formComplete = data?.study_type_id || data?.external_code;

    const filterOptions = createFilterOptions({
        stringify: ({ code,name }) => `${code} ${name}`
    });

    return(
        <Dialog
            open={open}
            fullWidth
        >
            <DialogTitle>
                <Typography>Precio</Typography>
            </DialogTitle>

            <DialogContent>
                <div className="column-container">
                    <TextField 
                        id='id' 
                        key='id' 
                        label='id' 
                        size="small"
                        disabled
                        value={data?.id || ''} 
                        onChange={handleChange}
                        sx={{marginBottom:'0.5em'}}
                    />
                                        
                    <TextField 
                        id='external_code' 
                        key='external_code' 
                        label='Codigo Externo' 
                        size="small"
                        value={data?.external_code || ''} 
                        onChange={handleChange}
                        sx={{marginBottom:'0.5em'}}
                    />
                    
                    <TextField 
                        id='external_name' 
                        key='external_name' 
                        label='Nombre Externo' 
                        size="small"
                        value={data?.external_name || ''} 
                        onChange={handleChange}
                        sx={{marginBottom:'0.5em'}}
                    />

                    <TextField 
                        id='units' 
                        key='units' 
                        label='Unidades' 
                        size="small"
                        value={data?.units || ''} 
                        onChange={handleChange}
                        sx={{marginBottom:'0.5em'}}
                    />

                    <TextField 
                        id='value' 
                        key='value' 
                        label='Precio' 
                        size="small"
                        value={data?.value || ''} 
                        onChange={handleChange}
                        sx={{marginBottom:'0.5em'}}
                    />

                    <Autocomplete
                        id='study_type_id' 
                        key='study_type_id' 
                        name='study_type_id' 
                        label='Estudio'
                        variant="outlined"
                        size="small"
                        options={studyTypes}
                        getOptionLabel={({code,name,visible, lis_id}) => `${code} ${name} / ${visible ? 'Activo' : 'Inactivo'} / Id ${lis_id}`}
                        filterOptions={filterOptions}
                        onChange={(event,newValue)=>handleChange({target:{id:'study_type_id', value:newValue?.id || null }})}
                        value={studyTypes.find(study => study?.id == data?.study_type_id)}
                        renderInput={(params) => (
                          <TextField {...params} label="Estudio" variant="outlined" />
                        )}
                        sx={{marginBottom:'0.5em'}}
                    />
                </div>

            </DialogContent>
            <DialogActions>
                <Button onClick={()=>onClose()}>CERRAR</Button>
                <Button disabled={data===price || !formComplete} onClick={()=>onSave(data)}>GUARDAR</Button>
            </DialogActions>
        </Dialog>
    )
}

export default PriceDialog;