import { Add,  ClearAll,  CopyAll,  Edit, FileDownload, FileUpload, PendingActions, Remove, Visibility } from "@mui/icons-material";
import { Box, Button, Divider, LinearProgress, MenuItem, TextField, Typography } from "@mui/material";
import { DataGridPro } from "@mui/x-data-grid-pro";
import React, { useEffect, useState } from "react";
import NomenclatorDialog from "./NomenclatorDialog";
import { useDispatch, useSelector } from "react-redux";
import { searchPrices, selectNomenclators } from "../../state/selectors/nomenclators";
import { createNomenclator, createPrice, fetchNomenclators, fetchPrices, removePrice, saveNomenclator,  savePrice, selectNomenclator, unselectNomenclator } from "../../state/handlers/nomenclators";
import { format, parseISO } from "date-fns";
import PriceDialog from "./PriceDialog";
import { nomenclatorTypes, translateNomenclatorType } from "../../helpers/nomenclator";
import ImportPriceDialog from "./ImportPriceDialog";

const Nomenclators = () => {
    
    const dispatch = useDispatch();

    const [dialog,openDialog] = useState(false);
    const [priceDialog,openPriceDialog] = useState(false);
    const [importPriceDialog,openImportPriceDialog] = useState(false);
    const [selectedPrice,selectPrice] = useState({});
    const [priceFilter, setPriceFilter] = useState('');

    const {loading, selected, list} = useSelector(selectNomenclators);

    const prices = useSelector(searchPrices(priceFilter));

    const nomenclators_columns = [
        {
            field: 'id',
            headerName: 'Id',
            width: 30,
            sortable: false,
            type: 'number'
        },
        {
            field: 'name',
            headerName: 'Nombre',
            sortable: false,
            type: 'string',
            valueGetter:(params)=> params.row.name,
            flex:0.4
        },
        {
            field: 'efector',
            headerName: 'Efector',
            sortable: false,
            type: 'string',
            valueGetter:(params)=> params.row.efector?.name || 'Sin efector',
        },
        {
            field: 'created_at',
            headerName: 'Creado',
            sortable: false,
            flex: 0.3,
            valueGetter:(params)=> params.row.created_at && format(parseISO(params.row.created_at), 'dd/MM/yyyy HH:mm:ss'),
        }
    ];

    const price_columns = [
        {
            field: 'id',
            headerName: 'Id',
            width: 30,
            sortable: false,
            type: 'number'
        },
        {
            field: 'study_type',
            headerName: 'Estudio',
            sortable: false,
            type: 'string',
            valueGetter:(params)=> params.row.study_type ? `${params.row.study_type.code} / ${params.row.study_type.name}` : '*** Sin estudio ***',
            width: 300,
        },
        {
            field: 'external_code',
            headerName: 'Codigo Externo',
            sortable: false,
            valueGetter:(params)=> params.row.external_code,
            width: 120
        },
        {
            field: 'external_name',
            headerName: 'Nombre Externo',
            sortable: false,
            valueGetter:(params)=> params.row.external_name,
            width: 220
        },
        {
            field: 'value',
            headerName: 'Valor',
            sortable: false,
            type: 'number',
            valueGetter:(params)=> params.row.value,
            width: 80
        },
        {
            field: 'units',
            headerName: 'Unidades',
            sortable: false,
            type: 'number',
            valueGetter:(params)=> params.row.code,
            width: 80
        },
        {
            field: 'edit',
            headerName:'',
            sortable: false,
            type: 'action',
            renderCell: (params) => <Button startIcon={<Visibility />} onClick={()=>handleEditPrice(params.row)}/>,
            width: 80
        },
        {
            field: 'remove',
            headerName:'',
            sortable: false,
            type: 'action',
            renderCell: (params) => <Button startIcon={<Remove />} onClick={()=>handleRemovePrice(params.row)}/>,
            width: 80
        }


    ];

    useEffect(() => {
        dispatch(fetchNomenclators());
        return () => {
        };
    }, []);

    const handleSave = (data) => 
    {
        data.id ? dispatch(saveNomenclator(data)) : dispatch(createNomenclator(data));
        openDialog(false);
    }

    const handleSavePrice = (data) => 
    {
        data.id ? dispatch(savePrice(selected.id, data)) : dispatch(createPrice(selected.id, data));
        openPriceDialog(false);
    }

    const handleEditPrice = (price) => {
        selectPrice(price);
        openPriceDialog(true);
    }

    const handleRemovePrice = (price) => {
        dispatch(removePrice(selected.id, price));
    }

    const handleNewPrice = () => {
        selectPrice({});
        openPriceDialog(true);
    }

    const handleNew = () => {
        dispatch(unselectNomenclator());
        openDialog(true);
    }

    const handleSelectNomenclator = (params) => {
        setPriceFilter('');
        dispatch(selectNomenclator(params.row));
    };

    const handleSelectPrice = (params) => {
        selectPrice(params.row);
    };

    const handleCloseImportPriceDialog = () => {
        openImportPriceDialog(false);
        dispatch(fetchPrices(selected?.id));
    }

    return(        
    
    <div className="column-container all-height">
        <div className="header row-container align-center" style={{height:'4em'}}>
            <div>
                <Typography variant="h5" color="text.black">
                    <Box fontWeight={750}>Nomencladores</Box>
                </Typography>
            </div>
        </div>
        
        <div style={{minHeight:'0.5em'}}>
                {loading && <LinearProgress/>}
        </div>

        <div className="body grow row-container margin-top margin-bottom">
            <div className="column-container" style={{ flex:1, marginRight:'1em', marginTop:'1em'}}>
                <div className="row-container" style={{marginBottom:'1em'}}>
                    <TextField 
                        variant="outlined" 
                        label="Buscar" 
                        size="small" 
                        fullWidth 
                    />
                </div>
                <div className="grow">
                    <DataGridPro
                            {...{rows:list,columns:nomenclators_columns}}
                            headerHeight={40}
                            rowHeight={40}
                            disableMultipleSelection
                            disableColumnMenu
                            disableColumnSelector
                            disableMultipleColumnsSorting
                            hideFooter
                            onCellClick={handleSelectNomenclator}
                            disableSelectionOnClick={loading}
                    />
                </div>
            </div>

            <div className="column-container" style={{ flex:2, marginLeft:'1em', marginTop:'1em'}}>
                <div className="row-container">
                    <div className="column-container grow" style={{ marginRight:'0.5em'}}>
                        
                        <div className="row-container" style={{ marginBottom:'0.5em'}} >
                            <TextField 
                                key='nomenclator.id'
                                label="Id" 
                                size="small" 
                                value={selected?.id || ''}
                                sx={{height:'2.8em',width:'5em',marginRight:'0.5em'}}
                            />

                            <TextField 
                                label="Creado" 
                                size="small"
                                value={selected?.created_at ? format(parseISO(selected.created_at), 'dd/MM/yyyy HH:mm:ss') : ''}
                                sx={{height:'2.8em',marginRight:'0.5em'}}
                            />

                            <TextField 
                                label="Tipo" 
                                size="small"
                                value={selected?.type ? translateNomenclatorType(selected?.type) : ''}
                                sx={{height:'2.8em',marginRight:'0.5em'}}
                            />
                        </div>
                        
                        <TextField 
                            key='nomenclator.name'
                            label="Nombre" 
                            size="small"
                            value={selected?.name || ''}
                            sx={{maxWidth:'40em',height:'2.8em', marginBottom:'0.5em'}}
                        />

                        <TextField 
                            label="Efector" 
                            size="small"                            
                            value={selected?.efector?.name || ''}
                            sx={{maxWidth:'40em',height:'2.8em'}}
                        />
                    </div>


                    <div className="column-container" style={{marginRight:'1em'}}>
                        <Button 
                            variant="outlined" 
                            startIcon={<Add/>}
                            sx={{height:'2.8em', marginBottom:'0.5em'}}
                            color="secondary"
                            onClick={()=>handleNew()}
                        >
                            Nuevo
                        </Button>

                        <Button 
                            variant="outlined" 
                            startIcon={<Edit/>}
                            sx={{height:'2.8em', marginBottom:'0.5em'}}
                            color="secondary"
                            onClick={()=>openDialog(true)}
                        >
                            Editar
                        </Button>

                        <Button 
                            variant="outlined" 
                            startIcon={<CopyAll/>}
                            sx={{height:'2.8em', marginBottom:'0.5em'}}
                            color="secondary"
                            disabled
                        >
                            Copiar
                        </Button>
                    </div>

                    <div className="column-container">
                        <Button 
                            variant="outlined" 
                            startIcon={<FileUpload/>}
                            sx={{height:'2.8em', marginBottom:'0.5em'}}
                            color="secondary"
                            disabled={!selected?.id}
                            onClick={()=>openImportPriceDialog(true)}
                        >
                            Importar
                        </Button>
                        
                        <Button 
                            variant="outlined"
                            startIcon={<FileDownload />}
                            sx={{height:'2.8em'}}
                            color="secondary"
                            disabled
                        >
                            Exportar
                        </Button>
                    </div>
                </div>

                <Divider orientation="horizontal" flexItem sx={{marginTop:'2em',marginBottom:'2em'}}/>

                <div className="row-container" style={{marginBottom:'1em'}}>
                    <TextField 
                        variant="outlined" 
                        label="Buscar un estudio" 
                        size="small" 
                        fullWidth
                        disabled={!selected?.id} 
                        value={priceFilter}
                        onChange={(event)=>setPriceFilter(event.target.value)}
                    />

                    <Button 
                        variant="outlined"
                        startIcon={<Add />}
                        sx={{marginLeft:'0.5em',height:'2.8em'}}
                        color="secondary"
                        onClick={()=>handleNewPrice()}
                        disabled={!selected?.id}
                    >
                        PRECIO
                    </Button>
                </div>

                {
                    /*
                    <div className="row-container" style={{marginBottom:'1em'}}>
                        <Button 
                            variant="outlined"
                            startIcon={<PendingActions />}
                            sx={{height:'2.8em'}}
                            color="secondary"
                            disabled={!selected?.id}
                        >
                            SIN ESTUDIO ASOCIADO
                        </Button>

                        <Button 
                            variant="outlined"
                            startIcon={<ClearAll />}
                            sx={{marginLeft:'0.5em',height:'2.8em'}}
                            color="secondary"
                            disabled={!selected?.id}
                        >
                            TODAS
                        </Button>
                    </div>
                    */
                }

                <div className="grow" >
                    <DataGridPro
                        {...{rows:prices || [],columns:price_columns}}
                        headerHeight={40}
                        rowHeight={40}
                        disableMultipleSelection
                        disableColumnMenu
                        disableColumnSelector
                        disableMultipleColumnsSorting
                        hideFooter
                        onCellClick={handleSelectPrice}
                        disable={loading}
                    />
                </div>
            </div>
        </div>

        {
            dialog &&
            <NomenclatorDialog 
                open={dialog} 
                onClose={()=>openDialog(false)} 
                onSave={handleSave} 
                nomenclator={selected}>

            </NomenclatorDialog>
        }

        {
            priceDialog &&
            <PriceDialog 
                open={priceDialog} 
                onClose={()=>openPriceDialog(false)} 
                onSave={handleSavePrice} 
                price={selectedPrice}>
            </PriceDialog>
        }

        {
            importPriceDialog && 
            <ImportPriceDialog
                open={importPriceDialog}
                onClose={handleCloseImportPriceDialog}
                selected={selected}
            />
        }


    </div>
)};

export default Nomenclators;