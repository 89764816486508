import React, {  useState } from "react";
import { Button, Dialog, DialogContent, DialogTitle,LinearProgress,MenuItem,Paper,TextField,Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { selectCustomers } from "../../state/selectors/catalogs.customers";
import { months } from "../../helpers/months";
import { selectImportData } from "../../state/selectors/production";
import { clearImportData, importProduction } from "../../state/handlers/production";
import { FileUpload } from "@mui/icons-material";
import ExceptionsDialog from "./ExceptionsDialog";

const ImportProductionDialog = ({open, onClose, initParams}) => {

    const dispatch = useDispatch();
    const customersCatalog = useSelector(selectCustomers);
    
    const [params, setParams] = useState(initParams);
    const [exceptionDialog,viewExceptions] = useState(false);
    const {tasks, status,exceptions} = useSelector(selectImportData);

    const handleStart = () => {
        dispatch(importProduction(params));
    }

    const handleClose = () => {

        dispatch(clearImportData());
        onClose();
    }

    const handleChange = (event) => {
        setParams({...params, [event.target.id || event.target.name]:event.target.value});
    }

    return(
        <Dialog
            open={open}
            fullWidth
        >
            <DialogTitle>
                <Typography>Importar produccion</Typography>
            </DialogTitle>
            <DialogContent>

                <Paper className="column-container" style={{padding:'2em'}}>

                    <div className="row-container" style={{marginBottom:'0.5em'}}>
                        <TextField 
                            key="month"
                            id="month"
                            name="month"
                            label="Mes" 
                            size="small"
                            fullWidth
                            select
                            sx={{marginRight:'0.5em'}}
                            value={params?.month || ''}
                            onChange={handleChange}
                        >
                            {
                                months.map( month => 
                                    <MenuItem key={month.value} value={month.value}>
                                        {month.name}
                                    </MenuItem>
                                )
                            }            
                        </TextField>

                        <TextField 
                            key="year"
                            id="year"
                            name="year"
                            label="Año" 
                            size="small"
                            fullWidh        
                            value={params?.year || ''}
                            onChange={handleChange}         
                        />
                    </div>

                    <TextField
                        key="customer"
                        id="customer"
                        name="customer"
                        sx={{marginBottom:'0.5em'}} 
                        variant="outlined" 
                        size="small" 
                        label="Cliente"
                        select
                        onChange={handleChange}
                        value={params?.customer || ''}
                    >
                        <MenuItem key={'all'} value={'all'}>
                            {'Todos'}
                        </MenuItem>

                        {
                            customersCatalog.map(customer => (
                                <MenuItem key={customer.id} value={customer.id}>
                                    {customer.name}
                                </MenuItem>
                            ))
                        }
                    </TextField>    



                    <TextField
                        label='Tareas'
                        multiline
                        rows={10}
                        sx={{marginBottom:'1em'}}
                        value={tasks.join('\n')}
                        
                    >

                    </TextField>

                    {
                        status === 'working' && 
                        <LinearProgress/>
                    }

                </Paper>

                <div className="row-container" style={{marginTop:'2em'}}>
                    {
                        /*
                        status === 'finished' && exceptions.length &&
                        <Button variant="outlined" color="secondary" onClick={()=>viewExceptions(true)}>VER EXCEPCIONES</Button>
                        */
                    }

                    <div className="grow"></div>

                    <Button disabled={status !== 'idle' && status !== 'finished'} color="secondary" variant="outlined" onClick={handleClose} sx={{marginRight:'1em'}}>CERRAR</Button>
                    <Button disabled={status === 'working' || status === 'finished'} color="secondary" variant="contained" onClick={handleStart} startIcon={<FileUpload/>}>IMPORTAR</Button>

                </div>


            </DialogContent>

        </Dialog>
    )
}

export default ImportProductionDialog;