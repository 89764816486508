import { actionTypes } from '../actions/nomenclators';

import HttpService from "../../services/HttpService";

const receiveNomenclator = (nomenclator) => ({ type: actionTypes.receiveNomenclator, nomenclator });
const receiveNomenclators = (nomenclators) => ({ type: actionTypes.receiveNomenclators, nomenclators });
const receivePrice = (price) => ({ type: actionTypes.receivePrice, price });
const receivePrices = (prices) => ({ type: actionTypes.receivePrices, prices });

export const unselectNomenclator = () => ({ type: actionTypes.unselectNomenclator});

export const selectNomenclator = (nomenclator) => {
    return (dispatch) => {

        dispatch({ type: actionTypes.selectNomenclator, nomenclator});
        dispatch(fetchPrices(nomenclator.id));
    }
}

export const fetchNomenclators = () => {
    return (dispatch) => {
        dispatch({ type: actionTypes.fetchNomenclators});
        return HttpService.getInstance().getData(`resource/nomenclators`, 'user-token')
            .then(response => {
                dispatch(receiveNomenclators(response.data));
            })
    }
}

export const saveNomenclator = (data) => {
    return (dispatch) => {
        dispatch({ type: actionTypes.saveNomenclator});
        return HttpService.getInstance().putData(data,`resource/nomenclators/${data.id}`, 'user-token')
            .then(response => {
                dispatch(receiveNomenclator(response.data));
            })
    }
}

export const createNomenclator = (data) => {
    return (dispatch) => {
        dispatch({ type: actionTypes.saveNomenclator});
        return HttpService.getInstance().postData(data,`resource/nomenclators`, 'user-token')
            .then(response => {
                dispatch(receiveNomenclator(response.data));
            })
    }
}

export const fetchPrices = (nomenclatorId) => {
    return (dispatch) => {
        dispatch({ type: actionTypes.fetchPrices});
        
        return HttpService.getInstance().getData(`resource/nomenclators/${nomenclatorId}/prices`, 'user-token')
            .then(response => {
                dispatch(receivePrices(response.data));
            })
    }
}

export const savePrice = (nomenclatorId, price) => {
    return (dispatch) => {
        dispatch({ type: actionTypes.savePrice});
        return HttpService.getInstance().putData(price,`resource/nomenclators/${nomenclatorId}/prices/${price.id}`, 'user-token')
            .then(response => {
                dispatch(receivePrice(response.data));
            })
    }
}

export const createPrice = (nomenclatorId, price) => {
    return (dispatch) => {

        dispatch({ type: actionTypes.savePrice});

        return HttpService.getInstance().postData(price,`resource/nomenclators/${nomenclatorId}/prices`, 'user-token')
            .then(response => {
                dispatch(receivePrice(response.data));
        })
    }
}

export const removePrice = (nomenclatorId, price) => {
    return (dispatch) => {

        dispatch({ type: actionTypes.savePrice});

        return HttpService.getInstance().deleteData(`resource/nomenclators/${nomenclatorId}/prices/${price.id}`, 'user-token')
            .then(response => {

                const id = price.id;
                dispatch({ type: actionTypes.removePrice, id});
        })
    }
}

export const uploadNomenclatorPriceList = (nomenclatorId, file) => {
    return (dispatch) => {

        console.log(file);
        
        dispatch({ type: actionTypes.uploadFile});

        var formData = new FormData();
        formData.append("file", file);

        return HttpService.getInstance().postFile(formData, `resource/nomenclators/${nomenclatorId}/upload`, 'user-token')
            .then(response => {
                const fileName = response.data.fileName;
                dispatch({ type: actionTypes.confirmUploadedFile, fileName});
                dispatch(importNomenclatorPriceList(nomenclatorId, fileName));
            })
    }
}

const importNomenclatorPriceList = (nomenclatorId, fileName) => {
    return (dispatch) => {

        dispatch({ type: actionTypes.importPrices});

        return HttpService.getInstance().getData(`resource/nomenclators/${nomenclatorId}/import/${fileName}`, 'user-token')
            .then(response => {
                const feedback = response.data.feedback;
                console.log(feedback);
                dispatch({ type: actionTypes.receivePricesImportFeedback, feedback});
            })
    }
}