import React, { Fragment, useEffect, useState } from 'react';
import { Visibility } from '@mui/icons-material';
import { Button, LinearProgress, TextField, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { useDispatch, useSelector } from 'react-redux';

import { searchCustomers, selectCustomersCatalog } from '../state/selectors/catalogs.customers';
import { setCustomer, fetchCustomers} from '../state/handlers/catalogs.customer';

import CustomerDialog from '../components/CustomerDialog';


const Customer = () => {

    const [filter, setFilter] = useState('');
    const [openDialog, setOpenDialog] = useState(false);

    const dispatch = useDispatch();
    
    useEffect(() => {
        dispatch(fetchCustomers());
    }, []);

    const columns = [
        {
            field: 'id',
            headerName: 'Id',
            width: 30,
            sortable: false,
            type: 'number',
            hide:true,
        },
        {
            field: 'code',
            headerName: 'Codigo',
            sortable: false,
            type: 'string',
            flex: 1,
            width: 60,
        },
        {
            field: 'name',
            headerName: 'Nombre',
            sortable: false,
            flex: 1,
            minWidth: 110,
            renderCell: (params) => params.row.customer?.name,
        },
        {
            field: 'lis_id',
            headerName: 'LIS Id',
            sortable: false,
            flex: 1,
            minWidth: 55
        },
        {
            field: 'lis_physician_id',
            headerName: 'LIS Medico Id',
            sortable: false,
            flex: 1,
            minWidth: 55
        },
        {
            field: 'tax_identification',
            headerName: 'CUIT',
            sortable: false,
            flex: 1,
            minWidth: 110
        },
        {
            field: 'company_name',
            headerName: 'Razon Social',
            sortable: false,
            flex: 1,
            minWidth: 110
        },
        {
            field: 'discount',
            headerName: '% Dto.',
            sortable: false,
            flex: 1,
            minWidth: 110
        },
        {
            field: 'email',
            headerName: 'Email facturacion',
            sortable: false,
            flex: 1,
            minWidth: 110
        },
        {
            field: 'lis_ensurance_id',
            headerName: 'LIS Cobertura Id',
            sortable: false,
            flex: 1,
            minWidth: 110
        },
        {
            field: 'users',
            headerName: 'Usuarios',
            sortable: false,
            flex: 0.5,
            valueGetter:(params)=>params.row.users.map(user=>user.name).join(),
            minWidth: 110
        },
        {
            field: 'view',
            headerName: 'Ver',
            sortable: false,
            renderCell: (params) => <Button onClick={()=>handleSelect(params.row)} startIcon={<Visibility style={{color:'#696969'}}/>}/>,
            type: 'actions'
        }
    ];

    const {current,loading} = useSelector(selectCustomersCatalog);
    const customers = useSelector(searchCustomers(filter))

    const data = {rows:customers, columns:columns};

    const handleSelect = (customer) => {
        dispatch(setCustomer(customer));
        setOpenDialog(true);
    }

    const handleClose = (refresh) => {
        setOpenDialog(false);
        refresh && dispatch(fetchCustomers());
    } 

    return ( 
        <Fragment>
            <div className="column-container all-height">
                <div className="header row-container align-center">
                    <div>
                        <Typography variant="h5" color="text.black">
                            <Box fontWeight={750}>Clientes</Box>
                        </Typography>
                    </div>
                    <div className="grow"></div>
                    <div className="grow margin">
                        <TextField 
                            placeholder="Buscar" 
                            variant="standard" 
                            fullWidth
                            size="small"
                            onChange={(event)=>setFilter(event.target.value)}
                        />
                    </div>
                    <div  className='margin'>
                            <Button 
                                variant="contained"
                                color="secondary"
                                sx={{height:'2.5em', textTransform:'none', paddingLeft:'2em', paddingRight:'2em'}}
                                onClick={()=>handleSelect({})}>
                                    <Typography>
                                        Nuevo cliente
                                    </Typography>
                            </Button>
                        </div>
                </div>

                <div>
                    {
                        loading && <LinearProgress/>
                    }
                </div>


                <div className="body grow padding">
                        <DataGridPro
                            {...data}
                            headerHeight={40}
                            rowHeight={40}
                            disableMultipleSelection
                            disableColumnMenu
                            disableColumnSelector
                            disableMultipleColumnsSorting
                            hideFooter
                        />
                </div>
            </div>
        
        {
            openDialog  && <CustomerDialog open={openDialog} refresh={()=>handleClose(true)} close={()=>handleClose(false)} values={current}/>
        }
    </Fragment>

     );
}
 
export default Customer;