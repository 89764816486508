import React, { useState } from "react";
import { Button, IconButton, TextField, Typography } from "@mui/material";
import { DataGridPro, useGridApiRef } from "@mui/x-data-grid-pro";
import { useSelector } from "react-redux";
import { searchAllStudies, searchStudiesAndPractices } from "../../state/selectors/catalogs";
import { Add } from "@mui/icons-material";

const sampleTypes = (list) =>
{
    return(<div>{list.map(item => <div>{item.name}</div>)}</div>)
}


const columns = [
    {
        field: 'id',
        headerName: 'Id',
        width: 50,
        sortable: false,
        hide:true,
        type: 'number'
    },
    {
        field: 'lis_id',
        headerName: 'LIS Id',
        width: 80,
        sortable: false,
        type: 'number'
    },
    {
        field: 'code',
        headerName: 'Codigo',
        sortable: false,
        type: 'string',
        flex: 0.5,
        minWidth: 35,
    },
    {
        field: 'alt_code',
        headerName: 'Codigo alternativo',
        sortable: false,
        type: 'string',
        flex: 0.5,
        minWidth: 35,
    },
    {
        field: 'name',
        headerName: 'Nombre',
        sortable: false,
        flex: 1,
        minWidth: 150
    },
    {
        field: 'visible',
        headerName: 'Estado',
        sortable: false,
        flex: 1,
        minWidth: 150,
        renderCell: (params) => params.row.visible == true ? 'Activo' : 'Inactivo',
    },
    {
        field: 'sample',
        headerName: 'Tipo de muestra',
        sortable: false,
        flex: 1,
        minWidth: 150,
        renderCell: (params) => sampleTypes(params.row.sample_types),
    },
    {
        field: 'efector',
        headerName: 'Efector',
        sortable: false,
        flex: 1,
        minWidth: 150,
        renderCell: (params) => params.row.efector?.name,
    }
];

const StudyTypesExplorer = ({onSelect}) => {

    const apiRef = useGridApiRef();

    const [filter,setFilter] = useState('');
    const [filterModel,setFilterModel] = useState({items:[]});
    const studyTypes = useSelector(searchAllStudies(filter));


    const handleChangeFilter = (value) => {
        setFilter(value);
    }

    const handleSelect = (studyType) => {
        onSelect(studyType);
    }

    const data = {rows:studyTypes,columns:columns};

    return(
            <div className="column-container all-height">
                <div>
                    <TextField
                        value={filter}
                        fullWidth
                        size="small"
                        variant="outlined"
                        placeholder="Buscar un estudio"
                        onChange={(event)=>handleChangeFilter(event.target.value)}>
                    </TextField>
                </div>
                <div className="grow margin-top">
                    <DataGridPro
                        {...data}
                        apiRef={apiRef}
                        headerHeight={40}
                        rowHeight={40}
                        filterModel={filterModel}
                        disableColumnMenu
                        disableColumnSelector
                        disableMultipleSelection
                        disableMultipleColumnsSorting
                        hideFooter
                        onCellClick={(params) => handleSelect(params.row)}
                    />
                </div>
            </div>
    )
}

export default StudyTypesExplorer;