import { actionTypes } from '../actions/catalogs';
import HttpService from "../../services/HttpService";
import { addApiException } from './api';

const receivePracticeTypes = (practiceTypes) => ({ type: actionTypes.receivePracticeTypes, practiceTypes });
const receiveStudies = (studies) => ({ type: actionTypes.receiveStudies, studies });
const receiveRoutines = (routines) => ({ type: actionTypes.receiveRoutines, routines });
const receiveSampleTypes = (samples) => ({type:actionTypes.receiveSampleTypes, samples}) ;
const receiveTechnicalNote = (code, technicalNote) => ({type:actionTypes.receiveTechnicalNote, code, technicalNote}) ;
const receiveEfectors = (efectors) => ({type:actionTypes.receiveEfectors,efectors});
const receiveRoles = (roles) => ({type:actionTypes.receiveRoles,roles});
export const setStudyType = (studyType) => ({type:actionTypes.selectStudyType,studyType});

export const addFavorite = (code) => {
    return(dispatch,getState)=>{

        const customerId = getState().profile.data.customer_id;
        const body = {study_type_code:code};

        return HttpService.getInstance().postData(body,`resource/customer/${customerId}/favorite`, 'user-token')
            .then(response => {
                dispatch({ type: actionTypes.addFavorite, code });
            })
            .catch(exception => dispatch(addApiException({uri:exception.config.url, message:exception.message})));
    }
};

export const removeFavorite = (code) => {
    return(dispatch,getState)=>{

        const customerId = getState().profile.data.customer_id;
        const body = {study_type_code:code};

        return HttpService.getInstance().deleteData(body,`resource/customer/${customerId}/favorite`, 'user-token')
            .then(response => {
                dispatch({ type: actionTypes.removeFavorite, code });
            })
            .catch(exception => dispatch(addApiException({uri:exception.config.url, message:exception.message})));
    }
};

const fetchStudies = () => {
    return(dispatch,getState) => {

        dispatch({type:actionTypes.fetchStudies});
        dispatch({type:actionTypes.fetchRoutines});
        dispatch({type:actionTypes.fetchSampleTypes});
        dispatch({type:actionTypes.fetchEfectors});

        const customerId = getState().profile.data.customer_id;

        if (customerId)
        {
            return HttpService.getInstance().getData(`customer/${customerId}/catalogs`, 'user-token')
            .then(response=> {
                dispatch(receiveRoutines(response.data.routines));
                dispatch(receiveStudies(response.data.studyTypes));
                dispatch(receiveSampleTypes(response.data.sampleTypes));
                dispatch(receiveEfectors(response.data.efectors));
            })
            .catch(exception => dispatch(addApiException({uri:exception.config.url, message:exception.message})));
        }

        return HttpService.getInstance().getData(`catalogs/studies`, 'user-token')
        .then(response=> {
            dispatch(receiveRoutines(response.data.routines));
            dispatch(receiveStudies(response.data.studyTypes));
            dispatch(receiveSampleTypes(response.data.sampleTypes));
            dispatch(receiveEfectors(response.data.efectors));
        })
        .catch(exception => dispatch(addApiException({uri:exception.config.url, message:exception.message})));


    }
}

export const fetchTechnicalNote = (code) => {
    return(dispatch) => {

        dispatch({type:actionTypes.fetchTechnicalNote});

        return HttpService.getInstance().getData(`catalogs/technical-note/${code}`, 'user-token')
            .then(response=> {
                dispatch(receiveTechnicalNote(code,response.data));
            })
            .catch(exception => dispatch(addApiException({uri:exception.config.url, message:exception.message})));
    }
}

export const fetchRoles = () => {
    return(dispatch) => {

        dispatch({type:actionTypes.fetchRoles});

        return HttpService.getInstance().getData(`catalogs/roles`, 'user-token')
            .then(response=> {
                dispatch(receiveRoles(response.data));
            })
            .catch(exception => dispatch(addApiException({uri:exception.config.url, message:exception.message})));
    }
}

export const fetchCatalogs = () => {
    return (dispatch,getState) => {
       if (getState().catalogs.status.catalogs !== 'ready'){
            dispatch({ type: actionTypes.fetchCatalogs });
            dispatch(fetchStudies());
            dispatch(fetchRoles());
        }
   }
}