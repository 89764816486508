import React from 'react';
import { Box, Typography, useMediaQuery } from '@mui/material';
import { useTheme } from '@emotion/react';
import { productionStatusIndex } from '../../helpers/productionStatus';

const ProductionStatus = ({status}) => {

    const theme = useTheme();
    const matchMD = useMediaQuery(theme.breakpoints.down('md'));

    const productionStatus = {
        pending: { style: { width: '10em', padding: '0.3em', color: 'white', backgroundColor: theme.palette.status.pending, borderRadius: 7 }, description: productionStatusIndex.pending, mobileDescription: 'P' },
        approved: { style: { width: '10em', padding: '0.3em', color: 'white', backgroundColor: theme.palette.status.pending, borderRadius: 7 }, description: productionStatusIndex.approved, mobileDescription: 'A' },
        liquidated: { style: { width: '10em', padding: '0.3em', color: 'white', backgroundColor: theme.palette.status.inProgress, borderRadius: 7 }, description: productionStatusIndex.liquidated, mobileDescription: 'L' },
        billed: { style: { width: '10em', padding: '0.3em', color: 'white', backgroundColor: theme.palette.status.inProgress, borderRadius: 7 }, description: productionStatusIndex.billed, mobileDescription: 'B' },
        canceled: { style: { width: '10em', padding: '0.3em', color: 'white', backgroundColor: theme.palette.status.partial, borderRadius: 7 }, description: productionStatusIndex.canceled,mobileDescription: 'C' },
    }

    return (
        <Box fontWeight={'600'}>
            <Typography 
                style={productionStatus[status].style}
                align="center"
                variant="body2">
                {matchMD ? productionStatus[status].mobileDescription: productionStatus[status].description}
            </Typography >
        </Box>

    );
};

export default ProductionStatus;