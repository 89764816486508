import HttpService from '../../services/HttpService';
import {actionTypes, setProfile, setTokens} from '../actions/profile';

export const user = (tokens = null) => {
    return (dispatch) => {

        dispatch({ type: actionTypes.fetchProfile });

        return HttpService.getInstance().getData('user', 'user-token')
            .then(response => {
                
                dispatch(setProfile(response.data));
            })
            .catch(error => {
                dispatch({ type: actionTypes.loginFailed });
            })
    }
}

export const login = (credentials) => {
    return (dispatch) => {

        dispatch({ type: actionTypes.tryLogin });
        return HttpService.getInstance().postData({...credentials, grant_type : "password", client_id : process.env.REACT_APP_PASSPORT_ID, client_secret : process.env.REACT_APP_PASSPORT_SECRET, scope : "*"}, 'login')
            .then(response => {
                HttpService.getInstance().setLocalToken(response.data.access_token);
                dispatch(setTokens(response.data));
            })
            .catch(error => {
                dispatch({ type: actionTypes.loginFailed });
            })
    }
}

export const logout = () => {
    return (dispatch) => {
        HttpService.getInstance().removeLocalToken();
        dispatch({ type: actionTypes.clearProfile });
    }
}

export const refreshToken = () => {
}

