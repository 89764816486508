
export const nomenclatorTypesIndex = {
    fixed:'Precio',
    unit:'Unidades'
}

export const nomenclatorTypes = [{key:'fixed',value:'Precio'},{key:'unit',value:'Unidades'}]

export const translateNomenclatorType = (value) =>
{
    return nomenclatorTypesIndex[value];
} 