
const practiceTypes = {
    fetchPracticeTypes : 'FETCH_PRACTICE_TYPES',
    receivePracticeTypes : 'RECEIVE_PRACTICE_TYPES',
    savePracticeType : 'SAVE_PRACTICE_TYPE',
    receivePracticeType : 'RECEIVE_PRACTICE_TYPE',
    clearPracticeTypes : 'CLEAR_PRACTICE_TYPES',
    addPracticeStudyType : 'ADD_PRACTICE_STUDY_TYPE',
    removePracticeStudyType : 'REMOVE_PRACTICE_STUDY_TYPE',
    editPracticeStudyType : 'EDIT_PRACTICE_STUDY_TYPE',
    receivePracticeStudyType : 'RECEIVE_PRACTICE_STUDY_TYPE',
}

export const actionTypes = {
    fetchCatalogs : 'FETCH_CATALOGS',
    clearCatalogs : 'CLEAR_CATALOGS',
    fetchStudies:'FETCH_STUDIES',
    receiveStudies:'RECEIVE_STUDIES',
    selectStudyType:'SELECT_STUDY_TYPE',
    fetchTechnicalNote:'FETCH_TECNICAL_NOTE',
    receiveTechnicalNote:'RECEIVE_TECNICAL_NOTE',
    fetchRoutines:'FETCH_ROUTINES',
    receiveRoutines:'RECEIVE_ROUTINES',
    fetchMeasurementTypes:'FETCH_MEASUREMENT_TYPES',
    receiveMeasurementTypes:'RECEIVE_MEASUREMENT_TYPES',
    receiveMeasurementType:'RECEIVE_MEASUREMENT_TYPE',
    fetchSampleTypes:'FETCH_SAMPLE_TYPES',
    receiveSampleTypes:'RECEIVE_SAMPLE_TYPES',
    addFavorite:'ADD_FAVORITE',
    removeFavorite:'REMOVE_FAVORITE',
    fetchEfectors:'FETCH_EFECTORS',
    receiveEfectors:'RECEIVE_EFECTORS',
    fetchRoles:'FETCH_ROLES',
    receiveRoles:'RECEIVE_ROLES',
    ...practiceTypes
};

