import { actionTypes } from '../actions/production';
import HttpService from "../../services/HttpService";

import { selectNextCustomer, selectNextCustomerToLiquidate } from '../selectors/production';


const setImportResults = (imported,exceptions) => ({type:actionTypes.setImportResults, imported,exceptions})
const setCustomerImported = (id) => ({type:actionTypes.setCustomerImported, id})
const addImportTask = (task) => ({type:actionTypes.addImportTask, task});
export const clearImportData = () => ({type:actionTypes.clearImportData});

const setLiquidationResults = (liquidated,exceptions) => ({type:actionTypes.setLiquidationResults, liquidated,exceptions})
const setCustomerLiquidated = (id) => ({type:actionTypes.setCustomerLiquidated, id})
const addLiquidationTask = (task) => ({type:actionTypes.addLiquidationTask, task});
export const clearLiquidationData = () => ({type:actionTypes.clearLiquidationData});

export const setParams = (params) => ({type:actionTypes.setParams, params});

export const recalculatePrice = (params) => {
    return (dispatch) => {

        dispatch({ type: actionTypes.fetchProduction });
        dispatch({ type: actionTypes.setParams, params });

        return HttpService.getInstance().getData(`billing/production/recalculate`, 'user-token', params)
            .then(response => {
                dispatch(fetchProduction(params));
            })
            .catch(error => {
                dispatch({type:actionTypes.clearProduction});
            })
    }
}

export const fetchProduction = (params) => {
    return (dispatch) => {

        dispatch({ type: actionTypes.fetchProduction });
        dispatch({ type: actionTypes.setParams, params });

        return HttpService.getInstance().getData(`billing/production`, 'user-token', params)
            .then(response => {
                const data = response.data;
                dispatch({type:actionTypes.receiveProduction, data});
            })
            .catch(error => {
                dispatch({type:actionTypes.clearProduction});
            })
    }
}

export const fetchProductionPage = (params,page) => {
    return (dispatch) => {

        dispatch({ type: actionTypes.fetchProduction });

        return HttpService.getInstance().getData(`billing/production?page=${page}`, 'user-token')
            .then(response => {
                const data = response.data;
                dispatch({type:actionTypes.receiveProduction, data});
            })
            .catch(error => {
                dispatch({type:actionTypes.clearProduction});
            })
    }
}




export const importProduction = (params) => {
    return (dispatch) => {

        dispatch({ type: actionTypes.startImportProcess });
        dispatch({ type: actionTypes.setImportParams, params });
        dispatch(addImportTask('Comenzando a importar la produccion.'));

        return HttpService.getInstance().getData(`billing/import/customers`, 'user-token', params)
            .then(response => {
                const customers = response.data;
                dispatch({type:actionTypes.setCustomersToImport, customers});
                dispatch(importNextCustomer(params));
            })
            .catch(error => {
                const customers = [];
                dispatch({type:actionTypes.setCustomersToImport, customers});
                dispatch({ type: actionTypes.finishImportProcess});
                dispatch(addImportTask('Proceso finalizado con errores'));
                dispatch(addImportTask(error));

            })
    }
}


export const importNextCustomer = (params) => {
    return (dispatch, getState) => {

        const customerToImport = selectNextCustomer(getState());

        if (customerToImport)
        {
            dispatch(addImportTask(`Importando: ${customerToImport.customer.name}`));

            return HttpService.getInstance().getData(`billing/import/customers/${customerToImport.customer.id}`, 'user-token',params)
            .then(response => {


                console.log(response.data);

                dispatch(setImportResults(response.data.imported, response.data.exceptions));
                dispatch(setCustomerImported(customerToImport.customer.id))

                dispatch(addImportTask(`Se importaron ${response.data.imported} practicas.`));
                dispatch(addImportTask(`Hubo ${response.data.exceptions} excepciones.`));

                dispatch(importNextCustomer(params));
            })
            .catch(error => {
            })
       }

       return Promise.resolve({})
       .then((record)=>{
           dispatch({ type: actionTypes.finishImportProcess});
           dispatch(addImportTask('Proceso finalizado.'));
       })
    }
}




export const liquidateProduction = (params) => {
    return (dispatch) => {

        dispatch({ type: actionTypes.startLiquidationProcess });
        dispatch({ type: actionTypes.setLiquidationParams, params });
        dispatch(addLiquidationTask('Comenzando a liquidar la produccion.'));

        return HttpService.getInstance().getData(`billing/liquidate/customers`, 'user-token', params)
            .then(response => {
                const customers = response.data;
                dispatch({type:actionTypes.setCustomersToLiquidate, customers});
                dispatch(liquidateNextCustomer(params));
            })
            .catch(error => {
                const customers = [];

                dispatch({type:actionTypes.setCustomersToLiquidate, customers});
                dispatch({ type: actionTypes.finishLiquidationProcess});
                dispatch(addLiquidationTask('Proceso finalizado con errores'));
                dispatch(addLiquidationTask(error));
            })
    }
}


export const liquidateNextCustomer = (params) => {
    return (dispatch, getState) => {

        const customerToLiquidate = selectNextCustomerToLiquidate(getState());

        if (customerToLiquidate)
        {
            dispatch(addImportTask(`Liquidando: ${customerToLiquidate.customer.name}`));

            return HttpService.getInstance().getData(`billing/liquidate/customers/${customerToLiquidate.customer.id}`, 'user-token',params)
            .then(response => {

                dispatch(setLiquidationResults(response.data.imported, response.data.exceptions));
                dispatch(setCustomerLiquidated(customerToLiquidate.customer.id))

                dispatch(addLiquidationTask(`Se liquidaron ${response.data.practices} practicas.`));
                dispatch(liquidateNextCustomer(params));
            })
            .catch(error => {
            })
       }

       return Promise.resolve({})
       .then((record)=>{
           dispatch({ type: actionTypes.finishLiquidationProcess});
           dispatch(addLiquidationTask('Proceso finalizado.'));
       })
    }
}