import React, { useEffect, useState } from "react";
import { AttachMoney,  Block, Search} from "@mui/icons-material";
import { Box, Button, LinearProgress, MenuItem, Paper, TextField, Typography } from "@mui/material";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { useDispatch, useSelector } from "react-redux";
import { selectCustomers } from "../../state/selectors/catalogs.customers";
import { months } from "../../helpers/months";

import { liquidationStatus } from "../../helpers/liquidationStatus";
import LiquidationStatus from "./LiquidationStatus";

import { fetchLiquidations, setParams } from "../../state/handlers/liquidation";
import { selectLiquidations } from "../../state/selectors/liquidation";
import { fetchCustomers } from "../../state/handlers/catalogs.customer";


const Liquidation = () => {
    
    const dispatch = useDispatch();

    const [locator,setLocator] = useState('');

    const customers = useSelector(selectCustomers);

    const {list, selected, loading, params} = useSelector(selectLiquidations);

    const liquidation_columns = [
        {
            field: 'id',
            headerName: 'Id',
            width: 30,
            sortable: false,
            type: 'number'
        },
        {
            field: 'month',
            headerName: 'Mes',
            sortable: false,
            type: 'string',
            valueGetter:(params)=> params.row.month,
        },
        {
            field: 'year',
            headerName: 'Año',
            sortable: false,
            type: 'string',
            valueGetter:(params)=> params.row.year,
        },
        {
            field: 'customer',
            headerName: 'Client',
            sortable: false,
            type: 'string',
            renderCell:(params)=> <Typography>{`${params.row.customer.code} ${params.row.customer.name}`}</Typography>,
            flex:1
        },
        {
            field: 'practices',
            headerName: 'Cantidad Practicas',
            sortable: false,
            type: 'string',
            flex:1
        },
        {
            field: 'samples',
            headerName: 'Cantidad de muestras',
            sortable: false,
            type: 'string',
            flex:1
        },
        {
            field: 'ammount',
            headerName: 'Sub total',
            sortable: false,
            type: 'string',
            flex:1
        },
        {
            field: 'discount',
            headerName: 'Descuento',
            sortable: false,
            type: 'string',
            flex:1
        },
        {
            field: 'ammount_balance',
            headerName: 'Total',
            sortable: false,
            type: 'money',
            
            flex:1
        },
        {
            field: 'status',
            headerName: 'Estado',
            sortable: false,
            type: 'string',
            renderCell:(params)=> <LiquidationStatus status={params.row.status}/> ,
            flex:1
        },
        {
            field: 'document_id',
            headerName: 'Documento',
            sortable: false,
            flex:1
        },
        {
            field: 'bill_id',
            headerName: 'Factura',
            sortable: false,
            flex:1
        },

    ];

    useEffect(() => {
        !customers?.lenght && dispatch(fetchCustomers());
    }, []);

    const handleChange = (event) => {
        dispatch(setParams({...params, [event.target.id || event.target.name]:event.target.value}));
    }

    const handleSampleSearch = () => {
        dispatch(fetchLiquidations({locator:locator}));
    }

    const handleSearch = () => {
        dispatch(fetchLiquidations(params));
    }

    const handleNoPriceChange = () => {
        dispatch(setParams({...params, noPrice: !params.noPrice}));
        dispatch(fetchLiquidations({...params, noPrice: !params.noPrice}));
    }

    return(        
        <div className="column-container all-height">
            <div className="header row-container align-center" style={{height:'4em'}}>
                <div>
                    <Typography variant="h5" color="text.black">
                        <Box fontWeight={750}>Liquidaciones</Box>
                    </Typography>
                </div>
            </div>
            
            <div style={{minHeight:'0.5em'}}>
                {   
                    loading &&
                    <LinearProgress/>
                }
            </div>

            <div className="body grow column-container margin-top margin-bottom">

                    <Paper elevation={3} className="row-container" style={{padding:'2em',marginTop:'1em',marginBottom:'1em'}}>

                        <div className="column-container flex-1" style={{ marginRight:'1em',paddingRight:'3em'}}>
                            <div className="row-container" style={{marginBottom:'0.5em'}}>
                                <TextField 
                                    key="month"
                                    id="month"
                                    name="month"
                                    label="Mes" 
                                    size="small"
                                    fullWidth
                                    select
                                    value={params?.month || ''}
                                    sx={{marginRight:'0.5em'}}
                                    onChange={handleChange}
                                >
                                    {
                                        months.map( month => 
                                            <MenuItem key={month.value} value={month.value}>
                                                {month.name}
                                            </MenuItem>
                                        )
                                    }            
                                </TextField>

                                <TextField 
                                    id="year"
                                    name="year"
                                    key="year"
                                    label="Año" 
                                    size="small"
                                    fullWidh   
                                    value={params?.year || ''}
                                    onChange={handleChange}              
                                />

                            </div>

                            <TextField 
                                id="customer"
                                name="customer"
                                key="customer"
                                sx={{marginBottom:'0.5em'}} 
                                variant="outlined" 
                                size="small" 
                                label="Cliente"
                                select
                                value={params?.customer || ''}
                                onChange={handleChange}  
                            >
                                <MenuItem key={'all'} value={'all'}>
                                    {'Todos'}
                                </MenuItem>

                                {
                                    customers.map(customer => (
                                        <MenuItem key={customer.id} value={customer.id}>
                                            {customer.name}
                                        </MenuItem>
                                    ))
                                }
                            </TextField>

                            <div className="row-container">

                                <TextField 
                                    id="status"
                                    name="status"
                                    key="status"
                                    sx={{marginRight:'0.5em'}} 
                                    variant="outlined" 
                                    size="small" 
                                    label="Estado"
                                    fullWidth
                                    select
                                    value={params?.status || ''}
                                    onChange={handleChange}
                                >
                                    {
                                        liquidationStatus.map(status => (
                                            <MenuItem key={status.value} value={status.value}>
                                                {status.name}
                                            </MenuItem>
                                        ))
                                    }
                                </TextField>


                                <Button 
                                    variant="contained" 
                                    sx={{height: '2.8em'}}
                                    color="secondary"
                                    onClick={handleSearch}
                                >
                                    <Search/>
                                </Button>

                            </div>
    
                        </div>

                        <div className="column-container flex-1" style={{ marginRight:'1em',paddingRight:'3em'}}>                        
                        </div>

                        <div className="column-container" style={{marginRight:'1em'}}>


                        </div>

                        <div className="column-container">
                            
                            <Button 
                                variant="outlined" 
                                startIcon={<Block/>}
                                sx={{height:'2.8em', marginBottom:'0.5em'}}
                                color="secondary"
                            >
                                Anular
                            </Button>

                            <div className="grow"></div>

                            <Button 
                                variant="contained" 
                                startIcon={<AttachMoney/>}
                                sx={{height:'2.8em', marginBottom:'0.5em'}}
                                color="secondary"
                            >
                                Facturar
                            </Button>

                        </div>
                    </Paper>

                    <div className="grow" >
                        <DataGridPro
                            {
                                ...{rows:list,columns:liquidation_columns}
                            }
                            headerHeight={40}
                            rowHeight={40}
                            disableMultipleSelection
                            disableColumnMenu
                            disableColumnSelector
                            disableMultipleColumnsSorting
                            hideFooter
                        />
                    </div>
            </div>

        </div>
    )};

export default Liquidation;