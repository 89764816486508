
import React from 'react';
import { Box, LinearProgress, Paper, Typography } from '@mui/material';

import StudyTypesExplorer from './StudyTypesExplorer';
import { useDispatch, useSelector } from 'react-redux';
import { addPracticeStudyType, editPracticeStudyType, removePracticeStudyType } from '../../state/handlers/catalogs.practiceTypes';
import {setStudyType } from '../../state/handlers/catalogs';
import { selectCatalogs, selectStudyType } from '../../state/selectors/catalogs';


const StudyTypes = () => {

    const dispatch = useDispatch();

    const loading = false;

    const handleSelectStudyType = (studyType) => {
        dispatch(setStudyType(studyType));
    }

    return (
        <div className="column-container all-height">
            <div className="header row-container align-center" style={{height:'4em'}}>
                <div>
                    <Typography variant="h5" color="text.black">
                        <Box fontWeight={750}>Estudios</Box>
                    </Typography>
                </div>
            </div>
            
            <div style={{minHeight:'0.5em'}}>
                    {loading && <LinearProgress/>}
            </div>

            <div className="body grow margin-top margin-bottom">
                
                    <StudyTypesExplorer onSelect={handleSelectStudyType}/>

            </div>
        </div>
    );
}
 
export default StudyTypes;