export const actionTypes = {
    fetchNomenclators : 'FETCH_NOMENCLATORS',
    receiveNomenclators : 'RECEIVE_NOMENCLATORS',
    saveNomenclator : 'SAVE_NOMENCLATOR',
    selectNomenclator : 'SELECT_NOMENCLATOR',
    unselectNomenclator:'UNSELECT_NOMENCLATOR',
    receiveNomenclator : 'RECEIVE_NOMENCLATOR',
    clearNomenclators : 'CLEAR_NOMENCLATORS',
    
    fetchPrices : 'FETCH_PRICES',
    receivePrices : 'RECEIVE_PRICES',
    savePrice: 'SAVE_PRICE',
    removePrice: 'REMOVE_PRICE',
    receivePrice : 'RECEIVE_PRICE',
    
    uploadFile: 'UPLOAD_PRICES_FILE',
    confirmUploadedFile: 'CONFIRM_UPLOAD_PRICES_FILE',

    importPrices : 'IMPORT_PRICES',
    receivePricesImportFeedback : 'IMPORT_PRICES_FEEDBACK'
};