import { combineReducers } from 'redux';
import { actionTypes } from '../actions/production';
import { format,subMonths } from 'date-fns';

const params_default = {
        month:format(subMonths(new Date(),1), 'M'),
        year : format(subMonths(new Date(),1), 'yyyy'),
        customer: 'all',
        status:'all',
        noPrice:false
    };


const params = (state = params_default, action) => {
    switch (action.type) {
        case actionTypes.setParams:
            return action.params
        default:
            return state
    }
}

const loading = (state = false, action) => {
    switch (action.type) {
        case actionTypes.fetchProduction:
            return true
        case actionTypes.receiveProduction:
            return false
        default:
            return state
    }
}

const data = (state = [], action) => {
    switch (action.type) {
        case actionTypes.fetchProduction:
            return []
        case actionTypes.receiveProduction:
            return action.data
        default:
            return state
    }
}

const selected = (state = [], action) => {
    switch (action.type) {
        case actionTypes.fetchProduction:
            return []
        case actionTypes.receiveProduction:
            return []
        default:
            return state
    }
}


export default combineReducers({ loading, data, selected,params});

