import { actionTypes } from '../actions/liquidation';
import HttpService from "../../services/HttpService";

const receiveLiquidations = (liquidations) => ({type:actionTypes.receiveLiquidations, liquidations});

export const setParams = (params) => ({type:actionTypes.setParams, params});

export const fetchLiquidations = (params) => {
    return (dispatch) => {

        dispatch({ type: actionTypes.fetchLiquidations });
        dispatch({ type: actionTypes.setParams, params });

        return HttpService.getInstance().getData(`billing/liquidations`, 'user-token', params)
            .then(response => {
                dispatch(receiveLiquidations(response.data));
            })
            .catch(error => {
                dispatch({type:actionTypes.clearLiquidations});
            })
    }
}