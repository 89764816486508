import { createSelector } from "reselect";

export const selectNomenclators = (state) => {
    return state.nomenclators;
}

export const selectPrices = (state) => {
    return state.nomenclators.selected?.prices;
}

export const searchPrices = (filter) => {
    return createSelector(
        selectPrices,
        prices => filter === "" ? prices : prices.filter(price => 
            String(price.study_type?.code).toLowerCase().includes(filter.toLowerCase()) || 
            String(price.study_type?.name).toLowerCase().includes(filter.toLowerCase()) || 
            String(price.external_code).toLowerCase().includes(filter.toLowerCase()) || 
            String(price.external_name).toLowerCase().includes(filter.toLowerCase()) 
    ))
};

