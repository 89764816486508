export const selectNextCustomer = (state) => {
    const customers = state.productionImport.customers;
    return customers.find(c=>c.status === 'pending');
}

export const selectProduction = (state) => {
    return state.production;
}

export const selectImportData = (state) => {
    return state.productionImport;
}


export const selectLiquidationData = (state) => {
    return state.productionLiquidate;
}

export const selectNextCustomerToLiquidate = (state) => {
    const customers = state.productionLiquidate.customers;
    return customers.find(c=>c.status === 'pending');
}
