export const actionTypes = {
    setParams : 'PRODUCTION_SET_PARAMS',
    fetchProduction : 'FETCH_PRODUCTION',
    receiveProduction : 'RECEIVE_PRODUCTION',
    clearProduction : 'CLEAR_PRODUCTION',

    setImportResults : 'PRODUCTION_SET_IMPORT_RESULTS',
    clearImportData : 'PRODUCTION_SET_CLEAR_IMPORT_DATA',
    setImportParams : 'PRODUCTION_SET_IMPORT_PARAMS',
    startImportProcess : 'PRODUCTION_START_IMPORT_PROCESS',
    finishImportProcess : 'PRODUCTION_FINISH_IMPORT_PROCESS',
    pauseImportProcess : 'PRODUCTION_PAUSE_IMPORT_PROCESS',
    continueImportProcess : 'PRODUCTION_CONTINUE_IMPORT_PROCESS',
    setCustomersToImport : 'PRODUCTION_SET_CUSTOMERS_TO_IMPORT',
    setCustomerImported : 'PRODUCTION_SET_CUSTOMER_IMPORTED',
    addImportTask : 'PRODUCTION_ADD_IMPORT_TASK',

    setLiquidationResults : 'PRODUCTION_SET_LIQUIDATION_RESULTS',
    clearLiquidationData : 'PRODUCTION_SET_CLEAR_LIQUIDATION_DATA',
    setLiquidationParams : 'PRODUCTION_SET_LIQUIDATION_PARAMS',
    startLiquidationProcess : 'PRODUCTION_START_LIQUIDATION_PROCESS',
    finishLiquidationProcess : 'PRODUCTION_FINISH_LIQUIDATION_PROCESS',
    pauseLiquidationProcess : 'PRODUCTION_PAUSE_LIQUIDATION_PROCESS',
    continueLiquidationProcess : 'PRODUCTION_CONTINUE_LIQUIDATION_PROCESS',
    setCustomersToLiquidate : 'PRODUCTION_SET_CUSTOMERS_TO_LIQUIDATE',
    setCustomerLiquidated : 'PRODUCTION_SET_CUSTOMER_LIQUIDATED',
    addLiquidationTask : 'PRODUCTION_ADD_LIQUIDATION_TASK'

};
