import { combineReducers } from 'redux';
import { actionTypes } from '../actions/nomenclators';

const loading = (state = false, action) => {
    switch (action.type) {
        case actionTypes.fetchNomenclators:
            return true
        case actionTypes.fetchPrices:
            return true
        case actionTypes.saveNomenclator:
            return true
        case actionTypes.receiveNomenclators:
            return false
        case actionTypes.receiveNomenclator:
            return false
        case actionTypes.savePrice :
            return true
        case actionTypes.receivePrice : 
            return false
        case actionTypes.receivePrices : 
            return false
        case actionTypes.removePrice:
            return false;
        default:
            return state
    }
}

const list = (state = [], action) => {
    switch (action.type) {
        case actionTypes.receiveNomenclator:
            return state.some(n=>n.id === action.nomenclator.id) ? [...state.map(n=> n.id === action.nomenclator.id ? action.nomenclator : n)] : [action.nomenclator, ...state]
        case actionTypes.receiveNomenclators:
            return action.nomenclators
        case actionTypes.clearNomenclators:
            return []
        default:
            return state
    }
}

const selected = (state = {}, action) => {
    switch (action.type) {

        case actionTypes.uploadFile:
            return {...state, import:{uploading:true}}
        case actionTypes.confirmUploadedFile:
            return {...state, import:{uploading:false, fileName:action.fileName}}
        case actionTypes.importPrices:
            return {...state, import:{...state.import, importing:true, feedback:{updated:0, created:0}}}
        case actionTypes.receivePricesImportFeedback:
            return {...state, import:{...state.import, importing:false, imported:true, feedback:action.feedback}}

        case actionTypes.selectNomenclator:
            return action.nomenclator
        case actionTypes.unselectNomenclator:
            return {}
        case actionTypes.receiveNomenclator:
            return action.nomenclator
        case actionTypes.receivePrices:
            return {...state, prices:action.prices}
        case actionTypes.receivePrice:
            return {...state, prices : state.prices.some(p=>p.id === action.price.id) ? [...state.prices.map(p=> p.id === action.price.id ? action.price : p)] : [action.price, ...state.prices]}
        case actionTypes.removePrice:
            return {...state, prices : state.prices.filter(p=>p.id !== action.id)}
        case actionTypes.receiveNomenclators:
            return {}
        case actionTypes.clearNomenclators:
            return {}
        default:
            return state
    }
}

export default combineReducers({ loading, list, selected});

