import React from "react";
import { useDispatch } from "react-redux";

import { Button, Dialog, DialogContent, DialogTitle, LinearProgress, Paper, TextField, Typography } from "@mui/material";
import { uploadNomenclatorPriceList } from "../../state/handlers/nomenclators";

const ImportPriceDialog = ({open, onClose,  selected}) => {

    const dispatch = useDispatch();

    const handleFileChange = (event) => {
        if (event.target.files[0] != null) {
            dispatch(uploadNomenclatorPriceList(selected.id, event.target.files[0]));
        }
    };

    return (
        <Dialog
            open={open}
            fullWidth
        >

            <DialogTitle>
                <Typography>Importar lista de precios</Typography>
            </DialogTitle>
            <DialogContent>

                <Paper className="column-container" style={{padding:'2em'}} elevation={3}>

                    <Typography>Seleccionar el archivo XLSX para importar.</Typography>
                    <Typography>El archivo debe contener estas 3 columnas: codigo, nombre y precio</Typography>

                    <TextField
                        id="file"
                        name="file"
                        variant="outlined"
                        fullWidth
                        inputProps={{
                            type: 'file',
                            onChange: handleFileChange
                        }}
                        sx={{marginTop:'2em'}}
                        disabled={selected?.import?.uploading || selected?.import?.importing}
                    />

                    {
                        (selected?.import?.uploading || selected?.import?.importing) &&
                        <LinearProgress sx={{marginTop:'2em'}}/>
                    }

                    {
                        (selected?.import?.imported) &&
                        <div className="column-containter" style={{marginTop:'2em'}}>
                            <Typography>{`Se han actualizado ${selected?.import?.feedback?.updated} precios.`}</Typography>
                            <Typography>{`Se han creado ${selected?.import?.feedback?.created} precios.`}</Typography>
                        </div>
                    }
                </Paper>

                <div className="row-container" style={{marginTop:'2em'}}>

                    <div className="grow"></div>
                    <Button disabled={selected?.import?.uploading || selected?.import?.importing} color="secondary" variant="outlined" onClick={onClose} sx={{marginRight:'1em'}}>CERRAR</Button>
                </div>
            </DialogContent>
        </Dialog>
    )
}

export default ImportPriceDialog;
