import axios from 'axios';
import { base } from '../constants/base';

let envs = {};

const axiosHandler = axios.create({
    baseURL: base(),
    headers: {
      "Content-Type": "application/json",
    },
  });

  const response = await axiosHandler.get(`/admin/env`);
  envs = response.data;

export default envs;

