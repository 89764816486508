import React from 'react';
import { Box, Typography, useMediaQuery } from '@mui/material';
import { useTheme } from '@emotion/react';
import { liquidationStatusIndex } from '../../helpers/liquidationStatus';


const LiquidationStatus = ({status}) => {

    const theme = useTheme();
    const matchMD = useMediaQuery(theme.breakpoints.down('md'));

    const liquidationStatus = {
        pending: { style: { width: '10em', padding: '0.3em', color: 'white', backgroundColor: theme.palette.status.pending, borderRadius: 7 }, description: liquidationStatusIndex.pending, mobileDescription: 'P' },
        billed: { style: { width: '10em', padding: '0.3em', color: 'white', backgroundColor: theme.palette.status.inProgress, borderRadius: 7 }, description: liquidationStatusIndex.billed, mobileDescription: 'B' },
        canceled: { style: { width: '10em', padding: '0.3em', color: 'white', backgroundColor: theme.palette.status.partial, borderRadius: 7 }, description: liquidationStatusIndex.canceled,mobileDescription: 'C' },
        inProgress:{ style: { width: '10em', padding: '0.3em', color: 'white', backgroundColor: theme.palette.status.inProgress, borderRadius: 7 }, description: liquidationStatusIndex.inProgress, mobileDescriptFion: 'IP' },
        failed:{ style: { width: '10em', padding: '0.3em', color: 'white', backgroundColor: theme.palette.status.inProgress, borderRadius: 7 }, description: liquidationStatusIndex.failed, mobileDescription: '' },

    }

    return (
        <Box fontWeight={'600'}>
            <Typography 
                style={liquidationStatus[status].style}
                align="center"
                variant="body2">
                {matchMD ? liquidationStatus[status].mobileDescription: liquidationStatus[status].description}
            </Typography >
        </Box>

    );
};

export default LiquidationStatus;