import React, { useState } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, MenuItem, TextField, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { selectEfectors } from "../../state/selectors/catalogs";
import { nomenclatorTypes } from "../../helpers/nomenclator";

const NomenclatorDialog = ({open, onClose, onSave, nomenclator}) => {
    
    const [data,setData] = useState(nomenclator);
    const efectors = useSelector(selectEfectors);
    const handleChange = (event) => {
        setData({...data,[event.target.id || event.target.name]:event.target.value});
    }

    const formComplete = data.type && data.name && data.efector_id;

    return(
        <Dialog
            open={open}
        >
            <DialogTitle>
                <Typography>Nomenclador</Typography>
            </DialogTitle>
            <DialogContent>

                <div className="column-container">
                    <TextField 
                        id='id' 
                        key='id' 
                        label='id' 
                        size="small"
                        disabled
                        value={data?.id} 
                        onChange={handleChange}
                        sx={{marginBottom:'0.5em'}}
                    ></TextField>
                                        
                    <TextField 
                        id='name' 
                        key='name' 
                        label='Nombre' 
                        size="small"
                        value={data?.name} 
                        onChange={handleChange}
                        sx={{marginBottom:'0.5em'}}
                    ></TextField>
                    
                    <TextField 
                        id='type' 
                        key='type' 
                        name='type' 
                        label='Tipo'
                        size="small"
                        select 
                        value={data?.type} 
                        onChange={handleChange}
                        sx={{marginBottom:'0.5em'}}
                    >
                        {
                            nomenclatorTypes.map(t => (
                                <MenuItem key={t.key} value={t.key}>
                                    {t.value}
                                </MenuItem>
                            ))
                        }
                    </TextField>

                    <TextField 
                        id='efector_id' 
                        key='efector_id' 
                        name='efector_id' 
                        label='Efector'
                        size="small"
                        select 
                        value={data?.efector_id} 
                        onChange={handleChange}
                        sx={{marginBottom:'0.5em'}}
                    >
                        {
                            efectors.map(e => (
                                <MenuItem key={e.id} value={e.id}>
                                    {e.name}
                                </MenuItem>
                            ))
                        }
                    </TextField>

                </div>

            </DialogContent>
            <DialogActions>
                <Button onClick={()=>onClose()}>CERRAR</Button>
                <Button disabled={data===nomenclator || !formComplete} onClick={()=>onSave(data)}>GUARDAR</Button>
            </DialogActions>
        </Dialog>

    )
}

export default NomenclatorDialog;