export const productionStatusIndex = {
    billed: "FACTURADO",
    liquidated: "LIQUIDADO",
    canceled:"CANCELADO",
    pending:'PENDIENTE',
    approved:'APROBADO'
}

export const productionStatus = [
    {value:'all', name:'Todos'},
    {value:'pending', name:'Pendiente'},
    {value:'approved', name:'Aprobado'},
    {value:'liquidated', name:'Liquidado'},
    {value:'canceled', name:'Anulado'}
];