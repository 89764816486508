export const base = () => {
    
    const base = process.env.REACT_APP_BASE;
    
    if(base){
        return base + '/api' 
    }

    return window.location.origin + '/api';
}

