import { combineReducers } from 'redux';
import batches from './batches.js'
import batch from './batch.js'
import profile from './profile.js'
import patients from './patients.js'
import currentOrder from './currentOrder';
import measurementTypes from './measurementTypes';
import resultsExplorer from './resultsExplorer';
import catalogs from './catalogs';
import sampleRanges from './sampleRanges';
import ordersToSend from './ordersToSend';
import api from './api.js';
import explorer from './explorer.js';
import nomenclators from './nomenclators.js';
import production from './production.js';
import productionImport from './production.import.js';
import productionLiquidate from './production.liquidate.js';
import liquidations from './liquidations.js';
import { actionTypes } from '../actions/profile.js';

const appReducer = combineReducers({profile,batches,batch,patients, currentOrder,measurementTypes,resultsExplorer,catalogs,sampleRanges,ordersToSend,api,explorer,nomenclators, production, productionImport, productionLiquidate, liquidations});

const rootReducer = (state, action) => {
    if (action.type === actionTypes.clearProfile) {
      return appReducer(undefined, action)
    }
    return appReducer(state, action)
}

export default rootReducer;