export const liquidationStatusIndex = {
    billed: "FACTURADO",
    pending:'PENDIENTE',
    canceled:'ANULADO',
    failed:'ERROR',
    inProgress:'EN PROGRESO'
}

export const liquidationStatus = [
    {value:'all', name:'Todos'},
    {value:'pending', name:'Pendiente'},
    {value:'billed', name:'Facturado'},
    {value:'canceled', name:'Anulado'},
    {value:'failed', name:'Error'},
    {value:'inProgress', name:'En progreso'},
];