import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Dashboard from '../views/Dashboard';
import Batch from '../views/Batch';
import Order from '../views/Order';
import Sample from '../views/Sample';
import Measurement from '../views/Measurement';
import ProtectedRoute from './ProtectedRoute';
import Routine from '../views/Routine';
import Barcode from '../views/Barcode';
import User from '../views/User';
import Customer from '../views/Customer';
import Profile from '../views/Profile';
import Login from '../views/Login';
import FastSample from '../views/FastSample';
import Explorer from '../views/Explorer';
import ResultsExplorer from '../views/ResultsExplorer';
import Nomenclators from '../views/billing/Nomenclators';
import Production from '../views/billing/Production';
import Liquidation from '../views/billing/Liquidation';
import StudyTypes from '../views/billing/StudyTypes';


const Router = () => {
    return (
        <Routes>
            <Route path="/" element={<Login/>}/>
            <Route path="/dashboard" element={<ProtectedRoute Component={Dashboard} scopes={["any"]} />}/>
            <Route path="/explorer" element={<ProtectedRoute Component={Explorer} scopes={["any"]} />}/>
            <Route path="/batch/:id" element={<ProtectedRoute Component={Batch} scopes={["any"]} />}/>
            <Route path="/order/:id" element={<ProtectedRoute Component={Order} scopes={["any"]} />}/>
            <Route path="/order" element={<ProtectedRoute Component={Order} scopes={["any"]} />}/>
            <Route path="/fast-sample/:id" element={<ProtectedRoute Component={FastSample} scopes={["any"]} />}/>
            <Route path="/fast-sample" element={<ProtectedRoute Component={FastSample} scopes={["any"]} />}/>
            <Route path="/results" element={<ProtectedRoute Component={ResultsExplorer} scopes={["any"]} />}/>
            <Route path="/sample" element={<ProtectedRoute Component={Sample} scopes={["any"]} />}/>
            <Route path="/sample/:id" element={<ProtectedRoute Component={Sample} scopes={["any"]} />}/>
            <Route path="/measurement" element={<ProtectedRoute Component={Measurement} scopes={["technical","admin"]} />}/>
            <Route path="/routines" element={<ProtectedRoute Component={Routine} scopes={["customer"]} />}/>
            <Route path="/barcodes" element={<ProtectedRoute Component={Barcode} scopes={["technical","admin"]} />}/>
            <Route path="/users" element={<ProtectedRoute Component={User} scopes={["admin"]} />}/>
            <Route path="/customers" element={<ProtectedRoute Component={Customer} scopes={["admin"]} />}/>
            <Route path="/study-types" element={<ProtectedRoute Component={StudyTypes} scopes={["admin","bill"]} />}/>
            <Route path="/nomenclators" element={<ProtectedRoute Component={Nomenclators} scopes={["admin","bill"]} />}/>
            <Route path="/production" element={<ProtectedRoute Component={Production} scopes={["admin","bill"]} />}/>
            <Route path="/liquidation" element={<ProtectedRoute Component={Liquidation} scopes={["admin","bill"]} />}/>
            <Route path="/accounts" element={<ProtectedRoute Component={Production} scopes={["any"]} />}/>
            <Route path="/profile" element={<ProtectedRoute Component={Profile} scopes={["any"]} />}/>
        </Routes>
    );
};

export default Router;