import { combineReducers } from 'redux';
import { actionTypes } from '../actions/production';


const params = (state = {}, action) => {
    switch (action.type) {
        case actionTypes.clearImportData:
            return {}
        case actionTypes.setParams:
            return action.params
        default:
            return state
    }
}

const tasks = (state = [], action) => {
    switch (action.type) {
        case actionTypes.clearImportData:
            return []
        case actionTypes.startImportProcess:
            return []
        case actionTypes.addImportTask:
            return [...state, action.task]
        default:
            return state
    }
}

const status = (state = 'idle', action) => {
    switch (action.type) {
        case actionTypes.clearImportData:
            return 'idle'
        case actionTypes.startImportProcess:
            return 'working'
        case actionTypes.finishImportProcess:
            return 'finished'
        case actionTypes.pauseImportProcess:
            return 'paused'
        case actionTypes.continueImportProcess:
            return 'working'
        default:
            return state
    }
}


const summary = (state = {imported:0,exceptions:0}, action) => {
    switch (action.type) {
        case actionTypes.setImportResults:
            return {imported:state.imported + action.imported ,exceptions:state.exceptions + action.exceptions}
        case actionTypes.clearImportData:
            return {imported:0,exceptions:0}
        default:
            return state
    }
}

const customers = (state = [], action) => {
    switch (action.type) {
        case actionTypes.clearImportData:
            return []
        case actionTypes.startImportProcess:
            return []
        case actionTypes.setCustomersToImport:
            return action.customers
        case actionTypes.setCustomerImported:
            return [...state.map(c => c.customer.id === action.id ? {...c, status:'imported'} : c)]
        default:
            return state
    }
}


export default combineReducers({params, status, tasks,summary, customers});

